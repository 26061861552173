@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap");
* {
	font-family: 'Balsamiq Sans', cursive;
}
.nsg-comparecastpopupcontainer {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1000;
	position: fixed;
	overflow: scroll;
	background: rgba(0, 0, 0, 0.7);
	text-align: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-transition: all 600ms ease-in-out;
	-moz-transition: all 600ms ease-in-out;
	-ms-transition: all 600ms ease-in-out;
	-o-transition: all 600ms ease-in-out;
	transition: all 600ms ease-in-out;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.nsg-comparecastopenpopup {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.nsg-comparecastclonsebtn {
	top: 80px;
	right: 15px;
	width: 36px;
	height: 36px;
	z-index: 15;
	font-size: 18px;
	cursor: pointer;
	margin: -70px 0 0;
	line-height: 36px;
	position: absolute;
	border-radius: 50%;
	text-align: center;
	background: #e04f5f;
	color: #fff !important;
}

.nsg-comparecast {
	width: 50%;
	float: left;
	height: 50%;
	position: relative;
	border: 1px solid #02a2de;
}

.at-screenimg {
	margin: 0;
	width: 100%;
	float: left;
	height: 100%;
	z-index: 10;
	background: #000;
	position: relative;
	border-radius: 2px;
}

.at-screenimg img {
	width: 100%;
	height: auto;
	display: block;
	border-radius: 2px;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-screenimg video {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.at-screenimg input {
	float: left;
	position: absolute;
	left: 10px;
}

.nsg-studentscreenholder {
}

.nsg-studentscreenholder span {
	font-size: 11px;
	width: 10px;
}

.nsg-studentscreenholder img {
	width: 16px;
	display: inline;
	margin-left: 9px;
	cursor: pointer;
}

.nsg-studentname {
	top: 20px;
	margin: 0;
	z-index: 1;
	left: 20px;
	padding: 4px;
	color: #02a2de;
	background: #fff;
	position: absolute;
	border-radius: 20px;
	padding: 0 2px 0 8px;
}

.nsg-studentname a {
	width: 25px;
	height: 25px;
	margin: 4px;
	cursor: pointer;
	font-size: 18px;
	line-height: 25px;
	border-radius: 50%;
	background: #e04f5f;
	display: inline-block;
	vertical-align: middle;
}

.nsg-studentname a i {
	color: #fff;
}
