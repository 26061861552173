/* video {
  width: 100%;
  height: auto;
  float: left;
  cursor: pointer;
}
.streamcomponent div {
  position: absolute;
  background: #f8f8f8;
  padding-left: 5px;
  padding-right: 5px;
  color: #777777;
  font-weight: bold;
  border-bottom-right-radius: 4px;
}
p {
  margin: 0;
} */

*::-webkit-media-controls-panel {
	display: none !important;

	-webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
	display: none !important;

	-webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
	display: none !important;

	-webkit-appearance: none;
}
